import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['select']
  
  updateStatus() {
    const id = this.selectTarget.dataset.id
    const csrfToken = document.querySelector("[name='csrf-token']").content;
  
    fetch(`/administration/external_data_imports/${id}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken, // Set the token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        treatment_status: this.selectTarget.selectedIndex
      }),
    });
  }
}
