import { Controller } from "stimulus"

export default class extends Controller {
  updateStatus() {
    const id = this.element.dataset.id
    const statusText = this.element.options[this.element.selectedIndex].text
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    const status_hash = {
      'Pas encore saisi': 0,
      'Saisie envoyée': 1,
      'Saisie refusée': 2,
      'Non pertinent': 3,
      'Déjà saisi': 4,
      'Saisie acceptée': 5,
      'Pertinent - pas encore saisi': 6,
      'Date dépassé': 7,
      'Doublon': 8,
      'Dossier envoyé': 9,
      'Dossier refusé': 10
    }
  
    fetch(`/administration/public_tenders/${id}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken, // Set the token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id, // params with id of the user
        status: status_hash[statusText]
      }),
    });
  }

  updateAttribute() {
    const id = this.element.dataset.id
    const attribute_name = this.element.dataset.attribute
    const attribute_value = this.element.value
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch(`/administration/public_tenders/${id}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken, // Set the token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id, // params with id of the user
        [attribute_name]: attribute_value
      }),
    });
  }

  updateComment() {
    const id = this.element.dataset.id
    const comment = this.element.value
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch(`/administration/public_tenders/${id}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken, // Set the token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id, // params with id of the user
        comment: comment
      }),
    });
  }
}
