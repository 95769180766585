import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['form', 'container', 'submit', 'textField', 'attachment', 'fileUploaded']
  
  connect () {
    const container = document.querySelector('.Messages__Content')
    container.scrollTop = container.scrollHeight
  }

  reset() {
    this.formTarget.reset()
    this.attachmentTarget.classList.remove('d-none')
    if (this.hasFileUploadedTarget) {
      this.fileUploadedTarget.remove()
    }
  }

  disableSubmit () {
    if (this.textFieldTarget.value === '') {
      this.submitTarget.disabled = true
    } else if (this.textFieldTarget.value !== '') {
      this.submitTarget.disabled = false
    }
  }

  hideChat () {
    this.containerTarget.classList.toggle('hide-chat')
  }

  updateFile (e) {
    this.attachmentTarget.insertAdjacentHTML('beforebegin', `<i class="fa fa-file me-3" data-toggle="tooltip" data-bs-title="${e.target.files[0].name}" data-chatbox-target="fileUploaded"></i>`)
    this.attachmentTarget.classList.add('d-none')
  }
}