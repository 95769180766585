// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

// Importing the main application js
// import 'js/application'
import 'bootstrap'

// Importing the SCSS
import "scss/application"
// Importing Font Awesome
import "@fortawesome/fontawesome-free/css/all"
import '@hotwired/turbo-rails'

Turbo.session.drive = false
Turbo.start()
Rails.start()
ActiveStorage.start()

import './theme'

// init flatpickr
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)
require("trix")
require("@rails/actiontext")