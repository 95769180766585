import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'counter']


  handleClick(e) {
    this.formTarget.action = e.currentTarget.dataset.url
    console.log(e.currentTarget.dataset.url)
    if (window.confirm('Voulez vous lancer l\'action ?') == true) {
      this.formTarget.submit()
    }
  }
}
