import { Controller } from "stimulus"

export default class extends Controller {
  approveUser() {
    const id = this.element.dataset.id
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/administration/users/approve_user", {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken, // Set the token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id, // params with id of the user
      }),
    });
  }
}
