import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['id', 'siret', 'enrichButtons', 'disabledButton']

  update() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    const tenderId = this.data.get('tenderid')
    const siret = this.siretTarget.value
    
    if (siret.length === 14) {
      fetch(`/administration/public_tenders/${tenderId}`, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrfToken, // Set the token
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: tenderId,
          buyer_siret: siret,
        }),
      });
    
      this.enrichButtonsTarget.classList.remove('d-none')
      this.disabledButtonTarget.classList.add('d-none')
    } else {
      this.enrichButtonsTarget.classList.add('d-none')
      this.disabledButtonTarget.classList.remove('d-none') 
    }
  }
}
