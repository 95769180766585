import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["phone", "email", "id"]

  update() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    const contactId = this.data.get('id')

    fetch(`/administration/contacts/${contactId}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken, // Set the token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: contactId,
        phone: this.phoneTarget.value,
        email: this.emailTarget.value
      }),
    });
  }
}
